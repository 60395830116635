// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-discography-tsx": () => import("./../src/pages/discography.tsx" /* webpackChunkName: "component---src-pages-discography-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-discography-article-tsx": () => import("./../src/templates/discography-article.tsx" /* webpackChunkName: "component---src-templates-discography-article-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../src/templates/single-page.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */)
}

